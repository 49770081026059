import chase from './photos/chase.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={chase} className="App-logo" alt="logo" />
        <p>
          My name is Chase. Hello to my world!
        </p>
      </header>
    </div>
  );
}

export default App;
